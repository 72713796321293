import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './404.module.scss';
import notThePageImage from '../assets/images/backgrounds/not-the-page.jpg';
import WipeLink from '../components/nav-transitions/wipe-link';

const NotFound = (_, ref) => {
  return (
    <div ref={ref} className={`${styles.notFoundWrapper}`}>
      <h1 className={'title-S'}>404 Page not found</h1>
      <h1 className={'title-M'}>This is not the page you're looking for... </h1>
      <img src={notThePageImage} alt='not found' />
      <WipeLink to={'/'}>
        <span className={'utility-overline-heavy'}>Back to Home</span>
      </WipeLink>
    </div>
  );
};

export default forwardRef(NotFound);
